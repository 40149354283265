import React, { useState, useEffect } from 'react';
import './ContactFirstLandigPage.css';
// import downArrow from './down-arrow.svg';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}


export default function ContactFirstLandigPage() {
  const [pageData, setPageData] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    contactNo: '',
    email: '',
    description: ''
  });
  let documentId = '';
  useEffect(() => {
    client
      .fetch('*[_type == "contactForm"][0]')
      .then((data) => {
        documentId = data._id;
        setPageData(data);
      })
      .catch((error) => console.error('Error fetching page data:', error));
  }, []);

  if (!pageData) {
    return <div></div>;
  }

  const { title, titleDescription, contactBtnText, contactBtnIcon, contactHeroRightImage, contactSubHeroImage, contactBoxTitle } = pageData;


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  async function updateAndPublishFormSubmission(sclient, existingContactFormId, formSubmission) {
    try {
      // First, update the draft document with the new submission
      const draftId = `drafts.${existingContactFormId}`;

      await sclient.transaction()
        .patch(draftId, patch => patch
          .setIfMissing({ submissions: [] }) // Ensure the submissions array exists on the draft
          .append('submissions', [formSubmission]) // Append the new submission
        )
        .commit({ visibility: 'async' });

      // Then, copy the draft content to the published document
      // This simulates a "publish" operation by ensuring the draft's content replaces the published document's content
      const draftDocument = await sclient.getDocument(draftId);
      await sclient.createOrReplace({
        ...draftDocument,
        _id: existingContactFormId, // Remove the draft prefix to target the published document
        _type: draftDocument._type,
      });

      console.log('Form submission updated and published successfully.');
    } catch (error) {
      console.error('Error updating and publishing form submission:', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const query = '*[_type == "contactForm"][0]';
      let existingContactForm = await client.fetch(query);

      if (!formData.name || !formData.contactNo || !formData.email || !formData.description) {
        alert('Please fill in all required fields.');
        return;
      }

      if (!existingContactForm) {
        console.error('Contact form document does not exist.');
        return;
      }

      // Create the form submission object
      const formSubmission = {
        _type: 'contactFormSubmission',
        ...formData,
      };

      // Check if `submissions` field exists, if not, initialize it
      if (!existingContactForm.submissions) {
        existingContactForm.submissions = [];
      }

      await updateAndPublishFormSubmission(client, existingContactForm._id, formSubmission)

      alert('Form data submitted successfully.');

      setFormData({
        name: '',
        contactNo: '',
        email: '',
        description: '',
      });

    } catch (error) {
      console.error('Error submitting form data:', error);
      alert('An error occurred while submitting the form. Please try again later.');
    }
  };

  function scrollToContactSection() {
    const contactSection = document.getElementById("contact-subHero");

    if (contactSection) {
      contactSection.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  return (
    <>
      <div className='contact-hero-container'>
        {/* Contact form */}

        <div className='contact-hero-left'>
          <h1>{title}</h1>
          <p>{titleDescription}</p>  {/* Variable for this text */}
          <button className='contact-us-btn' onClick={scrollToContactSection} onTouchStart={scrollToContactSection}>
            <span >{contactBtnText}</span> {/* Variable for this text */}
            <img src={urlFor(contactBtnIcon)} alt='/' />
          </button>
        </div>
        <div className='contact-hero-right'>
          <img src={urlFor(contactHeroRightImage)} alt='/contact-hero' />
        </div>
      </div>

      <div id="contact-subHero">
        <div className='contact-subHero-left'>
          <img src={urlFor(contactSubHeroImage)} alt='/contact-sub-hero' />
        </div>
        <div className='contact-subHero-right'>
        <form className='contact-form' onSubmit={handleSubmit}>
            <div className='subhero-inputBox-container'>
              <h2 className='subHero-form-heading'>{contactBoxTitle}<span>.</span></h2>
              <input type='text' name='name' value={formData.name} onChange={handleChange} placeholder='Name' required />
              <input type='tel' name='contactNo' value={formData.contactNo} onChange={handleChange} placeholder='Contact No.' required />
              <input type='email' name='email' value={formData.email} onChange={handleChange} placeholder='Email ID' required />
              <textarea name='description' value={formData.description} onChange={handleChange} placeholder='Description' className='description-box' required />
              <button type='submit'>Submit</button>
            </div>
          </form>
        </div>


      </div>
    </>
  );
}
