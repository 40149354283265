import client from "../sanityConfig/sanityClient";

export async function fetchBlogs() {
    try {
        const data = await client.fetch('*[_type == "blogContent"]');
        const allBlogs = data[0]?.blogBoxes || [];
        const uniqueCategories = ['All', ...new Set(allBlogs.map(blog => blog.category))];
        console.log('cate', uniqueCategories);

        // Successfully fetched data
        return {
            allBlogs, 
            uniqueCategories
        };
    } catch (error) {
        console.error('Error fetching blog data:', error);
        // Return default empty values in case of error
        return {
            allBlogs: [], 
            uniqueCategories: ['All']
        };
    }
}

export async function fetchPageContent() {
    try {
        const data = await client.fetch('*[_type == "pageContent"][0]');
        return data;
    } catch (error) {
        console.error('Error fetching blog data:', error);
        return null;  
    }
}
export async function fetchBlogPageFaqSchema() {
   try {
    const data = await client.fetch('*[_type == "faqBlogPageSchema"][0]');
    return data;

   }
   catch (error) {
    console.error('Error fetching blog data:', error);
    return null;  
}
}
export async function fetchBlogBackgroundImage(){
    try {
        const data = await client.fetch('*[_type == "backgroundImage"]{backgroundImage, dynamicText}');
        return data;
    }
    catch (error) {
        console.error('Error fetching blog data:', error);
        return null;  
    }
}
