
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPageNine.css';
import client from '../../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function LandingPageNine() {
    const [landingPageData, setLandingPageData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        let passedValue = localStorage.getItem('myData');
        if (passedValue !== "true") {
          window.location.reload();
          localStorage.setItem('myData', "true");
        }
        client
            .fetch('*[_type == "landingPageNine"][0]')
            .then((result) => {
                // console.log('Fetched data:', result);
                setLandingPageData(result);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    if (!landingPageData) {
        return <div></div>;
    }

    const handleParagraphClick = (route, index) => {
        if (route) {
            const newPath = route[index]; // Get the corresponding route for the clicked paragraph
            navigate(newPath); // Navigate to the new path
        }
    };


    const { footerLeftLogo, footerLeftDescription, aboutStuffin, forRestaurants, learnMore, socialLinks, playStoreMobileIcon, playStoreMobileIconUrl, playStoreDesktopIcon, playStoreDesktopIconUrl, footerBottomtext } = landingPageData;
    return (
        <div id="landing-page-nine">
            <div id="landing-page-nine-footer">
                <div id="footer-1">
                    <img src={urlFor(footerLeftLogo)} alt="/" />
                    <p>{footerLeftDescription}</p>
                </div>
                <div className="footer-2_3">
                    <div id="footer-2">
                        <h2>{aboutStuffin.heading}</h2>
                        {aboutStuffin.paragraphs.map((paragraph, index) => (
                            <p
                                key={index}
                                onClick={() => handleParagraphClick(aboutStuffin.routes, index)} // Pass the routes array and the index of the paragraph
                            >
                                {paragraph}
                            </p>
                        ))}
                    </div>
                    <div id="footer-3" className="footer-link">
                        <h2>{forRestaurants.heading}</h2>
                        {forRestaurants.paragraphs.map((paragraph, index) => (
                            <p
                                key={index}
                                onClick={() => handleParagraphClick(forRestaurants.routes, index)} // Pass the routes array and the index of the paragraph
                            >
                                {paragraph}
                            </p>
                        ))}
                    </div>
                </div>
                <div className="footer-4_5">
                    <div id="footer-4">
                        <h2>{learnMore.heading}</h2>
                        {learnMore.paragraphs.map((paragraph, index) => (
                               <p
                               key={index}
                               onClick={() => {
                                   handleParagraphClick(learnMore.routes, index); // Call handleParagraphClick
                                   localStorage.setItem('myData', "false"); // Set localStorage item
                               }}
                           >
                       
                                {paragraph}
                            </p>
                        ))}
                        <div id="download-file-mobile">
                            <a href={playStoreMobileIconUrl} target="_blank" rel="noopener noreferrer">
                                <img src={urlFor(playStoreMobileIcon)} alt="/" />
                            </a>
                        </div>
                    </div>
                    <div id="footer-5">
                        <h2>{socialLinks.title}</h2>
                        <div id="social-media-icon">
                            {socialLinks.icons.map((icon, index) => (
                                <a key={index} href={icon.url} target="_blank" rel="noreferrer">
                                    <img src={urlFor(icon.icon)} alt="/" />
                                </a>
                            ))}
                        </div>
                        <div id="download-file-pc">
                            <a href={playStoreDesktopIconUrl} target="_blank" rel="noopener noreferrer">
                                <img src={urlFor(playStoreDesktopIcon)} alt="/" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer-line">
                <hr />
                <p>{footerBottomtext}</p>
            </div>
        </div>
    );
}





