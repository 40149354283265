import React, { useState, useEffect } from "react";
import client from '../../sanityConfig/sanityClient';
import "./RefundPolicy.css";

function RefundPolicy() {
    const [refundPolicy, setRefundPolicy] = useState(null);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      client
        .fetch(`*[_type == "refundPolicy"][0]`)
        .then((data) => {
          console.log("Fetched refund policy data:", refundPolicy); // Log fetched data
          if (data) {
            setRefundPolicy(data);
          } else {
            setError("No refund policy data found");
          }
        })
        .catch((error) => {
          console.error("Error fetching refund policy:", error);
          setError("Error fetching refund policy data");
        });
    }, []);
  
    if (error) {
      return <div>Error: {error}</div>;
    }
  
    if (!refundPolicy) {
      return <div></div>;
    }

  return (
    <div id="refund-policy">
      <h2 id="refund-title">{refundPolicy.title}</h2>
      <p id="refund-paragraph"> {refundPolicy.paragraph} </p>
      <div id="cancel-list">
        <h2 className="list-heading">{refundPolicy.cancelAndRefundHeading}</h2>
        <ol type="1">
          {refundPolicy.cancelAndRefundList.map((item, index) => (
            <li key={index}>
              <span>{item.boldText}</span> {item.text}
            </li>
          ))}
        </ol>
      </div>
      <div id="processing-list">
        <h2 className="list-heading">{refundPolicy.processingTimeTitle}</h2>
        <ul>
          {refundPolicy.processingTimeList.map((item, index) => (
            <li key={index}>
              {item.text}
              {item.link && (
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                 {" "} {item.linkLabel}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
      <p id="legal-info">
        <span>{refundPolicy.legalInfo.boldText}</span>{" "}
        {refundPolicy.legalInfo.text}
      </p>
    </div>
  );
}

export default RefundPolicy;

// import React, {useEffect} from "react";
// import "./RefundPolicy.css";

// function RefundPolicy() {
    
//     useEffect (() =>
//     {
//       let passedValue = localStorage.getItem('myData');
//       if(passedValue !== "true"){
//           window.location.reload();
//           localStorage.setItem('myData', "true");
//       }
//     }, [])
//   return (
//     <div id="refund-policy">
//       <h2 id="refund-title">Refund Policy</h2>
//       <p id="refund-paragraph">
//         Thank you for choosing Stuffin, your go-to online tiffin delivery
//         application. At Stuffin, we strive to provide you with delicious meals
//         and exceptional service. To ensure clarity and transparency, please
//         review our refund policy below:
//       </p>

//       <div id="cancel-list">
//         <h2 className="list-heading">Cancellation and Refund</h2>
//         <ol type="1">
//           <li>
//             <span> Cancellation Before Delivery:</span> If you decide to cancel
//             your order before the meal has been delivered, the entire order
//             amount will be refunded to you.
//           </li>
//           <li>
//             <span> Cancellation After Preparation:</span> In the event that the
//             meal has been prepared but not yet delivered, the order amount will
//             be refunded after deducting the delivery charges.
//           </li>
//           <li>
//             <span> No Cancellation After Delivery:</span> Once the meal has been
//             delivered, the order cannot be canceled, and no refunds will be
//             provided.
//           </li>
//         </ol>
//       </div>
//       <div id="processing-list">
//         <h2 className="list-heading">Processing Time</h2>
//         <ul>
//           <li>
//             Please note that the refunded amount may take 2-3 business days to
//             reflect in your account, depending on your bank or payment
//             provider's processing time.
//           </li>
//           <li>
//             For any further assistance or queries regarding our refund policy,
//             feel free to reach out to our customer support team at{" "}
//             <a href="https://info@stuffin.in" target="blank">
//               info@stuffin.in.
//             </a>
//           </li>
//           <li>Thank you for your understanding and continued support.</li>
//         </ul>
//       </div>
//       <p id="legal-info">
//         <span>Legal Information:</span> Name: AKN FOOD PRIVATE LIMITED CIN:
//         U55101BR2023PTCO61654 Address: AKN FOOD PRIVATE LIMITED, C/O ARVIND
//         KUMAR SINGH, VILL-NONAUR(GANJ), TOLA-GANJ, POST-KARVASIN PANCH-NONAUR,
//         BLOCK- AGIAON, DIST- BHOJPUR, BIHAR 802201.
//       </p>
//     </div>
//   );
// }

// export default RefundPolicy;
